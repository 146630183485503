import React from 'react';
const ReturnPolicy =(props: any) => {
    return (
        <div style={{
            padding: 20,
            backgroundColor: "white",
            }} 
            className='returnPolicyContainer'>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "20px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              RETURN POLICY
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: ".25in",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              Last updated August 07, 2023
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.0pt",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            Thank you for your purchase. We hope you are happy with your
            purchase. However, if you are not completely satisfied with your
            purchase for any reason, you may return it to us for a full refund,
            store credit, or an exchange. Please see below for more information
            on our return policy.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: ".2in",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "14px",
                fontFamily: '"Tahoma",sans-serif',
                color: "black",
              }}
            >
              RETURNS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.0pt",
            marginRight: ".35in",
            marginLeft: "0in",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            All returns must be postmarked within seven (7) days of the purchase
            date. All returned items must be in new and unused condition, with
            all original tags and labels&nbsp;
          </span>
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            attached.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: ".25in",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "14px",
                fontFamily: '"Tahoma",sans-serif',
                color: "black",
              }}
            >
              RETURN PROCESS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.0pt",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            To return an item, email us at support@ironclub.pro and include your
            proof of purchase.&nbsp;
          </span>
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            Return shipping charges will be paid or reimbursed by us.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.6pt",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "14px",
                fontFamily: '"Tahoma",sans-serif',
                color: "black",
              }}
            >
              REFUNDS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.6pt",
            marginRight: ".1in",
            marginLeft: "0in",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            After receiving your return and inspecting the condition of your
            item, we will process your return or exchange. Please allow at least
            ten (10) days from the receipt of your&nbsp;
          </span>
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            item to process your return or exchange. Refunds may take 1-2
            billing cycles to appear on your credit card statement, depending on
            your credit card company. We will notify you by email when your
            return has been processed.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.6pt",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "14px",
                fontFamily: '"Tahoma",sans-serif',
                color: "black",
              }}
            >
              EXCEPTIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "9.0pt",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            For defective or damaged products, please contact us at the contact
            details below to arrange a refund or exchange.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "9.0pt",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              Please Note
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: ".05in",
            marginRight: "0in",
            marginLeft: ".15in",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            • Sale items are FINAL SALE and cannot be returned.
          </span>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: ".2in",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "14px",
                fontFamily: '"Tahoma",sans-serif',
                color: "black",
              }}
            >
              QUESTIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            marginTop: "12.6pt",
            marginRight: "4.85in",
            marginLeft: "0in",
          }}
        >
          <span
            style={{
              fontSize: "11px",
              fontFamily: '"Verdana",sans-serif',
              color: "black",
            }}
          >
            If you have any questions concerning our return policy, please
            contact us at:&nbsp;
          </span>
          <a href="mailto:support@ironclub.pro">
            <span
              style={{
                fontSize: "11px",
                fontFamily: '"Verdana",sans-serif',
                color: "blue",
              }}
            >
              support@ironclub.pro
            </span>
          </a>
        </p>
        <p
          style={{
            margin: "0in",
            marginBottom: ".0001pt",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          &nbsp;
        </p>
      </div>

    )
}

export default ReturnPolicy;
