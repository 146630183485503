import React from "react";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import Footer from "../components/Footer";

const IndexLayout = (props: any) => {
    return (
        <div className="indexContainer">
            <Header />
            <Introduction />
            <Footer />
        </div>
    )
}

export default IndexLayout;
