import React from "react";

const DeleteAccountRequest = (props: any) => {
  return (
    <div className='deleteAccountRequestContainer'>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "14.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        <strong>Request to delete account</strong>
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        If you wish to get in touch with us for account deletion, we encourage
        you to reach out via email. Please compose an email with the subject
        line "Account Deletion Request for Ironclub" and send it to our
        dedicated support email address:{" "}
        <a href="mailto:support@ironclub.pro">support@ironclub.pro</a> . In the
        body of the email, kindly use the following account deletion template,
        ensuring that you replace the placeholder with your accurate
        information. This format will help us efficiently locate your account
        and process your request. Once your email is ready, simply send it, and
        our support team will promptly attend to your query.
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Please note that we strive to respond to all emails within 5 working
        days. Your patience is greatly appreciated as we work diligently to
        address your request in a timely manner. Upon completion of the account
        deletion process, we will send you a detailed email outlining the
        actions that have been taken. This will provide you with transparency
        regarding the status of your request and the steps we have taken to
        fulfill it.
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Thank you for choosing Ironclub.
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        &nbsp;
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "14.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        <strong>Email template</strong>
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Subject: Account Deletion Request for Ironclub
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Dear Ironclub Support Team,
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        I am writing to formally request the deletion of my account associated
        with the Ironclub platform. I understand that this action is
        irreversible and will result in the removal of all my personal
        information and data from your system.
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Please find below the relevant information for my account:
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        Email Address: [Your Email Address]
      </p>
      <p
        style={{
          marginTop: "0in",
          marginRight: "0in",
          marginBottom: "8.0pt",
          marginLeft: "0.3in",
          fontSize: "11.0pt",
          fontFamily: '"Calibri",sans-serif',
        }}
      >
        I would appreciate it if you could confirm the deletion of my account as
        soon as the process is complete. Additionally, I understand that any
        content or data shared on the platform will also be permanently deleted.
      </p>
    </div>
  );
};

export default DeleteAccountRequest;
