import React from "react";
import { Link } from "react-router-dom";

const Footer = (props: any) => {
  return (
    <div className="siteFooter">
      <div className="footerMenuAll">
        <div className="footerMenu">
          <Link to="/" className="topHeaderLink">
            Home
          </Link>
          <Link to="/terms" className="topHeaderLink">
            Terms & Conditions
          </Link>
          <Link
            to="/privacy"
            className="topHeaderLink"
            onClick={props.privacyHandleClick}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
      <br />
      <div className="copyRight">
        Copyright © 2023 Lixone Inc. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;
