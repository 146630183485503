import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DeleteAccountRequest from "../components/DeleteAccountRequest";

const DeleteAccountRequestLayout = (props: any) => {
    return (
        <>
            <Header />
            <DeleteAccountRequest />
            <Footer />
        </>
    )
}

export default DeleteAccountRequestLayout;