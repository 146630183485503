import React from "react";
import Header from "../components/Header";
import Terms from "../components/Terms";
import Footer from "../components/Footer";

const TermsLayout = (props: any) => {
    return (
        <>
            <Header />
            <Terms />
            <Footer />
        </>
    )
}

export default TermsLayout;
