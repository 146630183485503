import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ReturnPolicy from "../components/ReturnPolicy";

const ReturnPolicyLayout = (props: any) => {
    return (
        <>
            <Header />
            <ReturnPolicy />
            <Footer />
        </>
    )
}

export default ReturnPolicyLayout;
