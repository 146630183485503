import React from "react";
import Header from "../components/Header";
import Introduction from "../components/Introduction";
import Privacy from "../components/Privacy";
import Footer from "../components/Footer";

const PrivacyLayout = (props: any) => {
    return (
        <>
            <Header />
            <Privacy />
            <Footer />
        </>
    )
}

export default PrivacyLayout;
