import React from "react";
import { Link } from "react-router-dom";

const Header = (props: any) => {
  return (
    <div className="headerContainer">
      <Link to="/" className="topHeaderLink">
        <div className="headerLogo">
          <img className="logoIcon" src="/assets/ironclubLogo.png" />
          <span className="headerParagraph">IRONCLUB</span>
        </div>
      </Link>
      <button className="hamburger">
        <span className="hamburgerIcon">☰</span>
      </button>
      <div className="headerMenu">
        <Link to="/">
          <span className="topHeaderLink">Home</span>
        </Link>
        <Link to="/terms" className="topHeaderLink">
          Terms & Conditions
        </Link>
        <Link
          to="/privacy"
          className="topHeaderLink"
          onClick={props.privacyHandleClick}
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
};

export default Header;
