import React from "react";

const Introduction = (props: any) => {
  return (
    <div className="introductionContainer">
      <div className="backgroundContainer">
        <img
          className="appIntroductionImage"
          src="/assets/appScreenshot.png"
          alt="Overlay Image"
        />
      </div>

      <div className="textFrameContainer">
        <p className="ironclubIntroductionHeading">Ironclub Fitness App</p>

        <p className="ironclubDescriptionParagraph">
          Access one of the most professional training services, including
          exercises, workout plans and advanced search with no additional access
          to your personal data.
        </p>
        <div className="bottomSectionContainer">
          <div className="iosContainer">
            <p className="iosAndroidParagraph">iOS</p>
            <div className="iconAndLinkContainer">
              <div className="storesIconContainer">
                <img src="/assets/appleIcon.png" className="storesIconStyle" />
              </div>
              <div className="linkContainer">
                <a
                  href="https://apps.apple.com/app/ironclub/id1099979413"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="downloadBox">Download for iOS</p>
                </a>
              </div>
            </div>
            <p className="buildParagraph">Version 2.2.0</p>
          </div>
          <div className="androidContainer">
            <p className="iosAndroidParagraph">Android</p>
            <div className="iconAndLinkContainer">
              <div className="storesIconContainer">
                <img
                  src="/assets/androidIcon.png"
                  className="storesIconStyle"
                />
              </div>
              <div className="linkContainer">
                <a
                  href="https://play.google.com/store/apps/details?id=ironclub.pro&hl=en&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="downloadBox">Download for Android</p>
                </a>
              </div>
            </div>
            <p className="buildParagraph">Version 2.2.0</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Introduction;
