import React from 'react';
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import IndexLayout from './layouts/IndexLayout';
import TermsLayout from './layouts/TermsLayout';
import PrivacyLayout from './layouts/PrivacyLayout';
import ReturnPolicyLayout from './layouts/ReturnPolicyLayout';
import DeleteAccountRequestLayout from './layouts/DeleteAccountRequestLayout';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={IndexLayout} />
        <Route path="/terms" Component={TermsLayout} />
        <Route path="/privacy" Component={PrivacyLayout} />
        <Route path="/privacy-policy" Component={PrivacyLayout} />
        <Route path="/return" Component={ReturnPolicyLayout} />
        <Route path="/request-to-delete-account" Component={DeleteAccountRequestLayout} />
      </Routes>
      <div
        style={{
          flex: 1,
          flexDirection: "column",
        }}
      >
       
      </div>
    </Router>
  );
}

export default App;
